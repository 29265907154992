import React from "react";
import loader from "./loader.gif";

const Loader = () => {
  return <div style={{ display: "flex", justifyContent: "center" }}>
    <img src={loader} alt="loader" />
  </div>;
};

export default Loader;
