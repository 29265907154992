import React, { useState, useEffect } from 'react';
import Loader from "./Loader";
import Error from "./Error";
import Message from "./Message";

type CodeResponse = {
  code: string;
  time: number;
};

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const getCode = () => {
    return fetch("https://twilio-app-review.picassomd.com/v1/recent_codes")
      .then((response) => response.json())
      .then((data) => {
        if (data.codes.length === 0) {
          setMessage("No codes received in the last 10 minutes.");
          return;
        }
        const sorted = data.codes.sort((a: CodeResponse, b: CodeResponse) => b.time - a.time);
        setMessage(`MFA Code for app-review@picassomd.com: ${sorted[0].code}`);
      })
      .catch((err) => {
        console.error(err);
        setError("Error getting the MFA code");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getCode();
  }, []);

  const getContent = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (error.length > 0) {
      return <Error message={error} />;
    }

    return <Message message={message} />;
  };

  return (
    <div className="body">
      {getContent()}
    </div>
  );
}

export default App;
