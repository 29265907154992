import React from "react";

type Props = {
  message: string;
};

const Error = ({ message }: Props) => {
  return <div className="error">
    {message}
  </div>;
};

export default Error;
